<div class="modal-content align-items-center">
  <div class="icon-position">
    <i class="icon-help-lg size-icon"></i>
  </div>
  <div class="modal-header">
    <h6 align="center" class="modal-title" id="exampleModalLabel">
      {{ textToShow | translate : { params: params } }}
    </h6>
  </div>
  <div *ngFor="let item of extraInfoParams; trackBy: trackByIndex">
    <div *ngIf="extraInfo" class="modal-body text-center p-0">
      <p class="m-3">{{ item | translate }}</p>
    </div>
  </div>

  <div *ngIf="showCheck" class="row planning">
    <div class="col-12">
      <div class="pba-check">
        <input class="pba-check-input" id="checkbox1" type="checkbox" [checked]="isChecked" (click)="onClickCheck()" />
        <label for="checkbox1" class="pba-check-text">{{
          'COMMONS.CHECK_PROGRAMAR' | translate
        }}</label>
        <label for="checkbox1" class="pba-check-mark"></label>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      *ngIf="showCancel && showButtons"
      type="button"
      class="btn btn-outline pba-btn-md cl-alert"
      (click)="onCancel()">
      {{ 'COMMONS.DECLINE' | translate }}
    </button>
    <button type="button" *ngIf="showButtons" class="btn btn-primary pba-btn-md cl-alert" (click)="onConfirm()">
      {{ 'COMMONS.ACCEPT' | translate }}
    </button>
  </div>
</div>
