export enum ConfigColumns {
  CLIENT_CRMID = 'CLIENT_CRMID',
  CLIENT_CLIENT_TYPE = 'CLIENT_CLIENT_TYPE',
  CLIENT_USERS = 'CLIENT_USERS',
  CLIENT_SLOTS = 'CLIENT_SLOTS',
  CLIENT_TOTALTERMINALS = 'CLIENT_TOTALTERMINALS',
  CLIENT_PROFILES = 'CLIENT_PROFILES',
  CLIENT_DOMAIN = 'CLIENT_DOMAIN',
  SLOT_CLIENT = 'SLOT_CLIENT',
  SLOT_PLATFORM = 'SLOT_PLATFORM',
  SLOT_VNOBILLING = 'SLOT_VNOBILLING',
  SLOT_FWD = 'SLOT_FWD',
  SLOT_RTN = 'SLOT_RTN',
  SLOT_BEAMS = 'SLOT_BEAMS',
  SLOT_ACTIVETERMINALS = 'SLOT_ACTIVETERMINALS',
  SLOT_TOTALTERMINALS = 'SLOT_TOTALTERMINALS',
  SLOT_PROFILE = 'SLOT_PROFILE',
  SLOT_SLOT_PARENT = 'SLOT_SLOT_PARENT',
  SLOT_SOLUTION_TYPE = 'SLOT_SOLUTION_TYPE',
  BEAM_CLIENT = 'BEAM_CLIENT',
  BEAM_SLOT = 'BEAM_SLOT',
  BEAM_FWDGARANTIZADA = 'BEAM_FWDGARANTIZADA',
  BEAM_RTNGARANTIZADA = 'BEAM_RTNGARANTIZADA',
  BEAM_FWDMAX = 'BEAM_FWDMAX',
  BEAM_RTNMAX = 'BEAM_RTNMAX',
  BEAM_ACTIVETERMINALS = 'BEAM_ACTIVETERMINALS',
  BEAM_TOTALTERMINALS = 'BEAM_TOTALTERMINALS',
  BEAM_CIR_AGREGATE_FWD = 'BEAM_CIR_AGREGATE_FWD',
  BEAM_CIR_AGREGATE_RTN = 'BEAM_CIR_AGREGATE_RTN',
  BEAM_VNO_CUENTAS = 'BEAM_VNO_CUENTAS',
  BEAM_NETWORK_SEGMENT = 'BEAM_NETWORK_SEGMENT',
  PROFILE_CLIENT = 'PROFILE_CLIENT',
  PROFILE_SLOT = 'PROFILE_SLOT',
  PROFILE_BEAM = 'PROFILE_BEAM',
  PROFILE_FWDMAX = 'PROFILE_FWDMAX',
  PROFILE_RTNMAX = 'PROFILE_RTNMAX',
  PROFILE_TOTALTERMINALS = 'PROFILE_TOTALTERMINALS',
  PROFILE_PRIORIZATION = 'PROFILE_PRIORIZATION',
  PROFILE_QUOTA = 'PROFILE_QUOTA',
  PROFILE_FREEZONE = 'PROFILE_FREEZONE',
  PROFILE_FWDENDQUOTA = 'PROFILE_FWDENDQUOTA',
  PROFILE_RTNENDQUOTA = 'PROFILE_RTNENDQUOTA',
  PROMOTIONS_CLIENT = 'PROMOTIONS_CLIENT',
  PROMOTIONS_TYPE = 'PROMOTIONS_TYPE',
  PROMOTIONS_ASSOCIATEDTERMINALS = 'PROMOTIONS_ASSOCIATEDTERMINALS',
  USERS_CLIENT = 'USERS_CLIENT',
  USERS_ROL = 'USERS_ROL',
  USERS_MAIL = 'USERS_MAIL',
  USERS_LASTACCESS = 'USERS_LASTACCESS',
  USERS_CREATION = 'USERS_CREATION',
  TERMINALTYPE_PLATFORM = 'TERMINALTYPE_PLATFORM',
  TERMINALTYPE_STATUS = 'TERMINALTYPE_STATUS',
  BULKACTIONS_USERS = 'BULKACTIONS_USERS',
  BULKACTIONS_CLIENTS = 'BULKACTIONS_CLIENTS',
  BULKACTIONS_TYPES = 'BULKACTIONS_TYPES',
  BULKACTIONS_STATUS = 'BULKACTIONS_STATUS',
  BULKACTIONS_TERMINALS = 'BULKACTIONS_TERMINALS',
  BULKACTIONS_EXECUTIONDATE = 'BULKACTIONS_EXECUTIONDATE',
  REPORTING_CLIENT = 'REPORTING_CLIENT',
  REPORTING_TYPE = 'REPORTING_TYPE',
  REPORTING_PERIODICITY = 'REPORTING_PERIODICITY',
  REPORTING_LASTEXECUTION = 'REPORTING_LASTEXECUTION',
  REPORTING_NEXTEXECUTION = 'REPORTING_NEXTEXECUTION',
  PUBLICIP_PREFIX = 'PUBLICIP_PREFIX',
  PUBLICIP_DESCRIPTION = 'PUBLICIP_DESCRIPTION',
  PUBLICIP_CLIENT = 'PUBLICIP_CLIENT',
  PUBLICIP_TERMINAL = 'PUBLICIP_TERMINAL',
  PUBLICIP_IP = 'PUBLICIP_IP',
  PUBLICIP_TICKET = 'PUBLICIP_TICKET',
  DOCUMENTATION_NAME = 'DOCUMENTATION_NAME',
  DOCUMENTATION_DESCRIPTION = 'DOCUMENTATION_DESCRIPTION',
  DOCUMENTATION_SIZE = 'DOCUMENTATION_SIZE',
  DOCUMENTATION_TYPE = 'DOCUMENTATION_TYPE',
  DOCUMENTATION_FILETYPE = 'DOCUMENTATION_FILETYPE',
  DOCUMENTATION_CLIENT = 'DOCUMENTATION_CLIENT',
  BILLING_CLIENT = 'BILLING_CLIENT',
  BILLING_PROFILE = 'BILLING_PROFILE',
  TERMINAL_PLATFORMNAME = 'TERMINAL_PLATFORMNAME',
  TERMINAL_CLIENT = 'TERMINAL_CLIENT',
  TERMINAL_IDENTIFIER = 'TERMINAL_IDENTIFIER',
  TERMINAL_SLOT = 'TERMINAL_SLOT',
  TERMINAL_BEAM = 'TERMINAL_BEAM',
  TERMINAL_PROFILE = 'TERMINAL_PROFILE',
  TERMINAL_IP = 'TERMINAL_IP',
  TERMINAL_MAC = 'TERMINAL_MAC',
  TERMINAL_TERMINALTYPE = 'TERMINAL_TERMINALTYPE',
  TERMINAL_TERMINALSTATUS = 'TERMINAL_TERMINALSTATUS',
  TERMINAL_ACTIVE = 'TERMINAL_ACTIVE',
  TERMINAL_CONSUMIDOCONTRATADO = 'TERMINAL_CONSUMIDOCONTRATADO',
  TERMINAL_QUOTACONSUM = 'TERMINAL_QUOTACONSUM',
  TERMINAL_RESETQUOTA = 'TERMINAL_RESETQUOTA',
  TERMINAL_ACTIVATIONDATE = 'TERMINAL_ACTIVATIONDATE',
  TERMINAL_VLAN = 'TERMINAL_VLAN',
  TERMINAL_OPTIONS = 'TERMINAL_OPTIONS',
  TERMINAL_LOTE = 'TERMINAL_LOTE',
  TERMINAL_PUBLIC_IP = 'TERMINAL_PUBLIC_IP',
  TERMINAL_IPV6 = 'TERMINAL_IPV6',
  TERMINAL_CREATION_DATE = 'TERMINAL_CREATION_DATE',
  TERMINAL_STATUS_MIGRATION = 'TERMINAL_STATUS_MIGRATION',
  TERMINAL_SNR_MEASURE = 'TERMINAL_SNR_MEASURE',
  TERMINAL_SNR_DATE = 'TERMINAL_SNR_DATE',
  TERMINAL_OLD_SERIAL_NUMBER = 'TERMINAL_OLD_SERIAL_NUMBER',
  TERMINAL_PROVISIONAL_REMOVE = 'TERMINAL_PROVISIONAL_REMOVE',
  TERMINAL_PROVISIONAL_REMOVE_COUNT = 'TERMINAL_PROVISIONAL_REMOVE_COUNT',
  TERMINAL_PROVISIONAL_REMOVE_ACTIVE = 'TERMINAL_PROVISIONAL_REMOVE_ACTIVE',
  HISTORYIP_COMMERCIALNAME = 'HISTORYIP_COMMERCIALNAME',
  HISTORYIP_CLIENT = 'HISTORYIP_CLIENT',
  HISTORYIP_IDENTIFIER = 'HISTORYIP_IDENTIFIER',
  HISTORYIP_MAC = 'HISTORYIP_MAC',
  HISTORYIP_INITDATE = 'HISTORYIP_INITDATE',
  HISTORYIP_ENDDATE = 'HISTORYIP_ENDDATE',
  HISTORYIP_PUBLICIP = 'HISTORYIP_PUBLICIP',
  TERMINALDELETE_DELETEDATE = 'TERMINALDELETE_DELETEDATE',
  TERMINALDELETE_NAME = 'TERMINALDELETE_NAME',
  TERMINALDELETE_IDENTIFIER = 'TERMINALDELETE_IDENTIFIER',
  TERMINALDELETE_SLOT = 'TERMINALDELETE_SLOT',
  TERMINALDELETE_PROFILE = 'TERMINALDELETE_PROFILE',
  TERMINALDELETE_ACTIVATIONDATE = 'TERMINALDELETE_ACTIVATIONDATE',
  TERMINALDELETE_USER = 'TERMINALDELETE_USER',
  TERMINALDELETE_CLIENT = 'TERMINALDELETE_CLIENT',
  TERMINAL_BEAM_MIGRATION = 'TERMINAL_BEAM_MIGRATION',
  TERMINALDELETE_CREATIONDATE = 'TERMINALDELETE_CREATIONDATE',
  TERMINALINVENTORING_NAME = 'TERMINALINVENTORING_NAME',
  TERMINALINVENTORING_NAME_INSTALATION = 'TERMINALINVENTORING_NAME_INSTALATION',
  TERMINALINVENTORING_IDENTIFIER = 'TERMINALINVENTORING_IDENTIFIER',
  TERMINALINVENTORING_CLIENT = 'TERMINALINVENTORING_CLIENT',
  TERMINALINVENTORING_ACTIVATION_DATE = 'TERMINALINVENTORING_ACTIVATION_DATE',
  TERMINALDELETE_DESPROVISIONINGDATE = 'TERMINALDELETE_DESPROVISIONINGDATE',
  TERMINALDELETE_ACTIVE_DAYS = 'TERMINALDELETE_ACTIVE_DAYS',
  TERMINALDELETE_MACSN = 'TERMINALDELETE_MACSN',
  TERMINALDELETE_IDU = 'TERMINALDELETE_IDU',
  TERMINALDELETE_ODU = 'TERMINALDELETE_ODU',
  TERMINAL_IDU = 'TERMINAL_IDU',
  TERMINAL_ODU = 'TERMINAL_ODU',
  TERMINAL_SERVICE_TYPE = 'TERMINAL_SERVICE_TYPE',
  TERMINAL_COUNTRY = 'TERMINAL_COUNTRY',
  TERMINAL_CITY = 'TERMINAL_CITY',
  TERMINAL_CASE_NUMBER= 'TERMINAL_CASE_NUMBER',
  TERMINAL_DEPARTURE= 'TERMINAL_DEPARTURE'
}
